// Import our CSS
import styles from '../../css/central/app.scss';

import Vue from 'vue'
import VueWaypoint from 'vue-waypoint'
import VueCookies from 'vue-cookies'

// Waypoint plugin
Vue.use(VueWaypoint)
Vue.use(VueCookies)

window.Vue = require('vue');

// App main
const main = async () => {
    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue({
        el: "#page-container",

        components: {
            'lightbox': () => import(/* webpackChunkName: "lightbox" */ '../../vue/Lightbox.vue'),
            'mobilemenu': () => import(/* webpackChunkName: "mobilemenu" */ '../../vue/central/MobileMenu.vue'),
            'apexchart': () => import(/* webpackChunkName: "apexchart" */ 'vue-apexcharts'),
            'dealticker': () => import(/* webpackChunkName: "dealticker" */ '../../vue/central/DealTicker.vue'),
        },
        
        data: {
            loadModalLink : '',
            lightboxImage: { url: '', title: '' },
            showEmergency: false,
            showVideo : false,
            intersectionOptions: {
                threshold: [0.25]
            }
        },

        mounted() {
            if( !$cookies.get('hideEmergMsg') ) {
                this.showEmergency = true
            }
        },

        methods: {
            handleScroll (event) {
                if( window.pageYOffset >= 100 ) {
                    document.body.classList.add("scrolled");
                }

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled");
                }
            },

            loadModal(event) {
                var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
                if( event.target.closest('a') ) {
                    if( isIE11 ) {
                        window.location.href = event.target.closest('a').href
                    } else {
                        this.loadModalLink = event.target.closest('a').href
                    }
                }
            },

            triggerModal(url) {
                var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
                if( isIE11 ) {
                    window.location.href = url
                } else {
                    this.loadModalLink = url
                }
            },

            unloadModal() {
                this.loadModalLink = ''
            },

            loadLightbox(image) {
                this.lightboxImage = image
            },

            unloadLightbox() {
                this.lightboxImage = { url: '', title: '' }
            },

            closeEmergency() {
                this.showEmergency = false
                $cookies.set('hideEmergMsg', true )
            },

            onWaypoint({ el, going, direction }) {
                if( this.$waypointMap.GOING_IN === going ) {
                    el.classList.add('active')
                }
            },

            playVideo() {
                this.showVideo = true
            }
        },

        created () {
            document.body.classList.add("js-loaded");
            window.addEventListener('scroll', this.handleScroll);
            setTimeout(() => document.body.classList.add( window.pageYOffset >= 10 ? "scrolled" : "notempty" ), 1000);
        },
       
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll);
        },
    });

    return vm;
};

// Execute async function
main().then( (vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
